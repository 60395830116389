const InputText = ({ label, name, register, error, required = true }) => {
  return (
    <div className="flex flex-col mx-auto justify-center items-center w-full">
      <label className="text-black w-full text-left">{label}</label>
      <input
        type="text"
        autoComplete="off"
        role="presentation"
        className="border-2 px-2 py-2 rounded-lg w-full text-left"
        {...register(name, {
          required: required,
        })}
      />
      {error && (
        <>
          <span className="text-red-500 text-sm text-left  w-full">
            This field is required
          </span>
        </>
      )}
    </div>
  );
};

export default InputText;
