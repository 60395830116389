import { useEffect, useState } from "react";
import QRScan from "../../components/qr_scan";
import { useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "../../hook/useQuery";
import Cookies from "js-cookie";
import helper from "../../utils/helper";

const PageLink = (props) => {
  const query = useQuery();

  const navigate = useNavigate();

  

  const logout = () => {
    Cookies.remove("token");
    window.location.href = "./login";
  };

  return (
    <>
      <div
       className="w-full min-h-screen flex flex-col justify-center items-center bg-gray-200 px-5 bg-cover bg-no-repeat"
       style={{
         backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg.jpg)`,
         backgroundSize: "cover",
         backgroundPosition: "center",
         width: "100%",
         height: "100%",
       }}
      >
        <div className="flex flex-col justify-center items-center p-4 rounded-md bg-opacity-60 bg-white">
          <div className="text-black font-bold mb-5 text-xl uppercase">
           LINK
          </div>
          <div className="flex flex-col justify-center items-center space-y-2.5 w-full">
            <a
              href={`./checkin`}
              className={`w-[220px] px-2 py-2 rounded-lg text-white font-bold border-b-4 border-blue-700 bg-blue-500  text-center`}
            >
              CHECK IN & RE-ENTRY
            </a>
            <a
              href={`./checkin-name`}
              className={`w-[220px] px-2 py-2 rounded-lg text-white font-bold border-b-4 border-green-700 bg-green-500  text-center`}
            >
              CHECK IN - NAME
            </a>
            <a
              href={`./checkout`}
              className={`w-[220px] px-2 py-2 rounded-lg text-white font-bold border-b-4 border-red-700 bg-red-500  text-center`}
            >
              CHECK OUT
            </a>
            {/* <a
              href={`./add-user`}
              className={`w-[220px] px-2 py-2 rounded-lg text-white font-bold border-b-4 border-green-700 bg-green-500  text-center`}
            >
              ADD USER
            </a> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default PageLink;
