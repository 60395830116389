import { useEffect, useState } from "react";
import QRScan from "../../components/qr_scan";
import { Controller, useForm } from "react-hook-form";
import CONFIG from "../../constanta/config";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import useUser from "../../hook/useUser";
import ReactSelect from "react-select";
import moment from "moment";
import { useQuery } from "../../hook/useQuery";
import helper from "../../utils/helper";

const FormRFIDResult = (props) => {
  const [loading, setLoading] = useState(false);
  const query = useQuery();
  const [checkAllow, setCheckAllow] = useState("");
  const [showGuest, setShowGuest] = useState(false);
  const [listGuest, setListGuest] = useState([]);
  const [isUserAttend, setIsUserAttend] = useState(false);
  const [guestAttend, setGuestAttend] = useState(false);

  useEffect(() => {
    initAllowed();
  }, [props.user]);

  useEffect(() => {
    if (checkAllow !== "") {
      if (checkAllow === "allowed-to-checkin") {
        setTimeout(() => {
          setLoading(true);

          handleCheckin();
        }, 0.5 * 1000);
        return;
      } else if (
        checkAllow === "allowed-to-re-checkin" &&
        props.user.rfid !== ""
      ) {
        return;
      }
    }
  }, [checkAllow]);

  const togleGuest = (idx) => {
    let arr = [...listGuest];
    arr[idx].active = !arr[idx].active;
    setListGuest(arr);
  };

  const onSubmit = () => {
    if (loading) return;

    setLoading(true);

    if (checkAllow === "allowed-to-checkin") {
      handleCheckin();
    } else {
      handleReCheckin();
    }
  };

  const handleReCheckin = () => {
    const payload = {
      id: props?.user?.id,
    };

    try {
      axios
        .post(CONFIG.URL + "/user/reentry", payload)

        .then(function (response) {
          setLoading(false);
          if (response.status === 200 && response.data?.status !== "error") {
            props.onSuccess();
          } else {
          }
        })
        .catch(function (error) {
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
    }
  };

  const handleCheckin = () => {
    const payload = {
      id: props?.user?.id,
    };

    try {
      axios
        .post(CONFIG.URL + "/user/checkin", payload)

        .then(function (response) {
          setLoading(false);
          if (response.status === 200 && response.data?.status !== "error") {
            props.onSuccess();
          } else {
          }
        })
        .catch(function (error) {
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
    }
  };

  const initAllowed = () => {
    if (props.user !== null) {
      if (props.user?.checkin_time !== "0000-00-00 00:00:00") {
        if (props.user?.checkout_time === "0000-00-00 00:00:00") {
          setCheckAllow("already-checkin");
        } else {
          if (
            moment(props.user.reentery_time) > moment(props.user.checkout_time)
          ) {
            setCheckAllow("already-checkin");
          } else {
            setCheckAllow("allowed-to-re-checkin");
          }
        }
      } else {
        setCheckAllow("allowed-to-checkin");
      }
    }
  };

  return (
    <>
      <div className="space-y-3 px-5 py-3 bg-white rounded-md items-center justify-center flex flex-col w-full md:w-1/2 transition-all">
        <div className="text-center font-bold">
          <div className="">MAIN GATE</div>
          <div className="text-2xl uppercase">CHECK IN</div>
        </div>

        {checkAllow === "allowed-to-checkin" && (
          <div className="bg-green-500 w-full text-center py-2 text-white font-bold rounded">
            ALLOWED TO CHECKIN
          </div>
        )}

        {checkAllow === "allowed-to-re-checkin" && (
          <div className="bg-green-500 w-full text-center py-2 text-white font-bold rounded">
            ALLOWED TO RE-ENTRY
          </div>
        )}

        {checkAllow === "already-checkin" && (
          <div className="bg-red-500 w-full text-center py-2 text-white font-bold rounded">
            ALREADY CHECKIN :{" "}
            {props.user?.reentery_time !== "0000-00-00 00:00:00"
              ? props.user?.reentery_time
              : props.user?.checkin_time}
          </div>
        )}

        {checkAllow === "not-allowed-to-checkin" && (
          <div className="bg-red-500 w-full text-center py-2 text-white font-bold rounded">
            NOT ALLOWED TO CHECKIN
          </div>
        )}

        {/* <div className="bg-red-500 w-full text-center py-5 text-white font-bold rounded">
          ALREADY CHECKED IN
        </div> */}

        <div
          className={`py-10 cursor-pointer text-white flex flex-col justify-center items-center  text-black font-bold px-5 py-1 rounded`}
        >
          {props.user !== null ? (
            <>
              {props.user?.photo !== "" && (
                <>
                  <div className="mb-2 flex flex-col justify-center items-center  text-black font-bold px-5 py-1 rounded">
                    {checkAllow === "allowed-to-re-checkin" && (
                      <img
                        src={CONFIG.URL_MEDIA + "/" + props.user?.photo}
                        className="rounded w-full h-full  bject-contain"
                        alt=""
                      />
                    )}
                  </div>
                </>
              )}

              {checkAllow === "allowed-to-checkin" && (
                <>
                  <div className="text-center text-3xl font-bold text-black capitalize mb-0">
                    {props.user?.type_user === "1"
                      ? props.user?.ticket_type
                      : props.user?.fullname}
                  </div>
                </>
              )}

              {checkAllow === "already-checkin" && (
                <>
                  <div className="text-center text-3xl font-bold text-black capitalize mb-0">
                    {props.user?.type_user === "1"
                      ? props.user?.ticket_type
                      : props.user?.fullname}
                  </div>
                </>
              )}

              {checkAllow === "allowed-to-re-checkin" && <></>}
            </>
          ) : (
            <>
              {" "}
              <div className="text-center  font-bold text-black text-4xl mt-4">
                NOT FOUND
              </div>
            </>
          )}
        </div>

        <>
          <div className="mx-auto flex justify-center items-center space-x-4">
            {checkAllow === "already-checkin" && (
              <button
                type="button"
                onClick={() => props.ok()}
                className={` border-green-700 bg-green-500 px-5 py-2 rounded-lg text-white font-bold border-b-4   w-[160px] text-center`}
              >
                CONFIRM
              </button>
            )}

            {checkAllow === "allowed-to-re-checkin" && (
              <>
                <button
                  type="button"
                  onClick={() => props.ok()}
                  className={`  px-2 py-2 rounded-lg text-white font-bold border-b-4 border-red-700 bg-red-500  w-[160px] text-center`}
                >
                  CANCEL
                </button>
                <button
                  type="button"
                  onClick={() => onSubmit()}
                  className={` border-green-700 bg-green-500 px-5 py-2 rounded-lg text-white font-bold border-b-4   w-[160px] text-center`}
                >
                  {loading ? "Loading..." : "CONFIRM"}
                </button>
              </>
            )}
          </div>
        </>
      </div>
    </>
  );
};

export default FormRFIDResult;
