import { useEffect, useState } from "react";
import QRScan from "../../components/qr_scan";
import { Controller, useForm } from "react-hook-form";
import CONFIG from "../../constanta/config";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import useUser from "../../hook/useUser";
import ReactSelect from "react-select";
import useReport from "../../hook/useReport";
import moment from "moment";
import { useQuery } from "../../hook/useQuery";
import helper from "../../utils/helper";

const FormVerification = (props) => {
  const localReport = useReport();
  const [loading, setLoading] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [focus, setFocus] = useState("ticket_code");
  const [msg, setMsg] = useState("");
  const [count, setCount] = useState(0);
  const [showQR, setShowQR] = useState(props.qrCode);

  useEffect(() => {
    setShowQR(props.qrCode);
  }, [props.qrCode]);

  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const query = useQuery();

  useEffect(() => {
    const interval = setInterval(() => {
      setCount(count + 1);
      handleCheckinTime();
    }, 1000 * 5);

    return () => clearInterval(interval);
  }, [count]);

  useEffect(() => {
    localReport.fetchData();
  }, []);

  const handleCheckinTime = async () => {
    localReport.fetchData();
  };

  useEffect(() => {
    setTimeout(() => {
      setMsg(null);
    }, 2000);
  }, [msg]);

  const onSubmit = (data) => {
    if (data.ticket_code === "" && data.rfid_reentry === "") {
      return;
    }

    if (focus === "ticket_code") {
      checkRFID(data);
    } else if (focus === "rfid_reentry") {
      checkRFIDREEntry(data);
    }
    return;
  };

  const checkRFIDREEntry = async (data) => {
    if (loading) return;

    setLoading(true);
    setMsg("");

    const payload = {
      rfid: data.rfid_reentry,
    };

    try {
      axios
        .post(CONFIG.URL + "/user/check-rfid", payload)
        .then(function (response) {
          setLoading(false);

          if (response.status === 200 && response.data?.status !== "error") {
            props.onSuccess(response?.data?.user, "rfid", showQR);
          } else {
            setMsg("Error: User not found");
          }
        })
        .catch(function (error) {
          setMsg("Error: Something went wrong please try again");
          setLoading(false);
        });
    } catch (error) {
      setMsg("Error: Something went wrong please try again");
      setLoading(false);
    }
  };

  const checkRFID = async (data) => {
    if (loading) return;

    setLoading(true);
    setMsg("");

    const payload = {
      code: data.ticket_code,
    };

    try {
      axios
        .post(CONFIG.URL + "/user/check-code", payload)
        .then(function (response) {
          setLoading(false);

          if (response.status === 200 && response.data?.status !== "error") {
            props.onSuccess(response?.data?.user, "rfid", showQR);
          } else {
            setMsg("Error: User not found");
          }
        })
        .catch(function (error) {
          setMsg("Error: Something went wrong please try again");
          setLoading(false);
        });
    } catch (error) {
      setMsg("Error: Something went wrong please try again");
      setLoading(false);
    }
  };

  const checkQRCode = async (data) => {
    if (loading) return;

    setLoading(true);
    setMsg("");

    try {
      axios
        .get(CONFIG.URL + "/user/" + data.qr_code, {
          headers: {
            token: Cookies.get("token"),
          },
        })
        .then(function (response) {
          setLoading(false);
          if (response?.data.status === "SUCCESS") {
            props.onSuccess(response?.data?.data[0], "qr_code", data.qr_code);
          } else {
            setMsg("Error: " + response.data?.message);
          }
        })
        .catch(function (error) {
          setMsg("Error: Something went wrong please try again");
          setLoading(false);
        });
    } catch (error) {
      setMsg("Error: Something went wrong please try again");
      setLoading(false);
    }
  };

  const checkName = async (data) => {
    if (loading) return;

    setLoading(true);
    setMsg("");

    try {
      axios
        .get(CONFIG.URL + "/user/" + data.rfid, {
          headers: {
            token: Cookies.get("token"),
          },
        })
        .then(function (response) {
          setLoading(false);
          if (response?.data.status === "SUCCESS") {
            props.onSuccess(response?.data?.data[0], "rfid");
          } else {
            setMsg("Error: " + response.data?.message);
          }
        })
        .catch(function (error) {
          setMsg("Error: Something went wrong please try again");
          setLoading(false);
        });
    } catch (error) {
      setMsg("Error: Something went wrong please try again");
      setLoading(false);
    }
  };

  const resetForm = () => {
    setValue("name", "");
    setValue("rfid", "");
    setValue("qr_code", "");
    setValue("rfid_reentry", "");
    reset();
  };

  const calcCheckinRegisteredUser = () => {
    if (localReport?.data) {
      let total = 0;

      total =
        parseInt(localReport?.data[0]?.total_checkins) -
        parseInt(localReport?.data[0]?.total_checkouts) +
        parseInt(localReport?.data[0]?.total_reentries);

      if (total < 0) {
        return 0;
      } else {
        return total;
      }
    }
  };

  const calcCheckinAddedUser = () => {
    if (localReport?.data) {
      let total = 0;

      total =
        parseInt(localReport?.data[1]?.total_checkins) -
        parseInt(localReport?.data[1]?.total_checkouts) +
        parseInt(localReport?.data[1]?.total_reentries);
      if (total < 0) {
        return 0;
      } else {
        return total;
      }
    }
  };

  const calcTotalCheckin = () => {
    if (localReport?.data?.length > 0) {
      return (
        parseInt(localReport?.data[0]?.total_checkin) -
        parseInt(localReport?.data[0]?.total_checkout) +
        parseInt(localReport?.data[0]?.total_reentry)
      );
    } else {
      return 0;
    }
  };

  const handleCloseScan = () => {
    setShowQR(false);
  };

  const handleSuccess = (qr) => {
    handleCloseScan();
    checkRFID({
      rfid: qr,
    });
    //checkDataUser(qr);
  };

  const handleFailed = () => {
    setShowQR(false);
  };

  return (
    <>
      {showQR && (
        <QRScan
          onClose={() => handleCloseScan()}
          onSuccess={(data) => handleSuccess(data)}
          onFailed={() => handleFailed()}
          gate="CHECK IN"
          event={""}
        />
      )}
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="relative space-y-5 px-5 py-5 bg-white rounded-md w-full md:w-3/4 z-0"
      >
        <div className="text-center font-bold mb-10">
          {/* <div className="text-2xl">{focus}</div> */}
          <div className="">MAIN GATE</div>
          <div className="text-2xl uppercase">CHECK IN</div>
          {/*<div className="text-black font-semibold text-xl">
            Registered Users: {calcCheckinRegisteredUser() || 0}
          </div>
          <div className="text-black font-semibold text-xl">
            Added Users: {calcCheckinAddedUser() || 0}
          </div>*/}

          <div className="text-black font-semibold text-xl">
            Currently Checked In: {calcTotalCheckin() || 0}
          </div>

          {localReport?.data?.length > 0 && (
            <div className="flex justify-center items-center space-x-5">
               <div className="text-black font-semibold text-xl">
                Added : {localReport?.data[0]?.total_added_checkin || 0}
              </div>

              <div className="text-black font-semibold text-xl">
                Scanned : {localReport?.data[0]?.total_scanned_checkin || 0}
              </div>

              <div className="text-black font-semibold text-xl">
                Name : {localReport?.data[0]?.total_name_checkin || 0}
              </div>
            </div>
          )}
        </div>

        <div className="grid grid-cols-1 gap-4">
          {/* SCAN QR CODE */}
          {/* <div className="flex flex-col">
            <div className="flex justify-between mb-1">
              <label className="text-black w-full font-bold">
               Scan QR Code CODE
              </label>
              <button
                type="button"
                onClick={() => resetForm()}
                className="border-b-2 border-red-700  bg-red-500 px-2 py-1 text-white text-xs rounded font-bold"
              >
                Clear
              </button>
            </div>

            <input
            autoFocus
              autoComplete="off"
              role="presentation"
              onFocus={() => setFocus("qr_code")}
              className="border-2 px-2 h-[38px] rounded"
              {...register("qr_code", { required: false })}
            />
          </div> */}

          {/* SEARCH NAME */}
          <div className="relative z-0 flex flex-col">
            <div className="flex justify-between mb-1">
              <label className="text-black w-full font-bold">Enter Name</label>
            </div>

            <Controller
              control={control}
              name="ticket_code"
              rules={{ required: false }}
              render={({ field: { onChange, value, ref, name } }) => (
                <ReactSelect
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  isClearable
                  dropdown
                  onFocus={() => setFocus("ticket_code")}
                  className={"react-select"}
                  classNamePrefix={"react-select"}
                  placeholder={"Search Name"}
                  options={props.options}
                  onInputChange={(val) => {
                    if (val.length <= 2) {
                      setOpenMenu(false);
                    } else {
                      setOpenMenu(true);
                    }
                  }}
                  onChange={(val) => {
                    onChange(val?.value);
                  }}
                  menuIsOpen={openMenu}
                  openMenuOnClick={false}
                  openMenuOnFocus={false}
                />
              )}
            />
            <span className="text-xs text-gray-500 mt-1">
              *type at least three characters
            </span>

            <span className="text-xs text-gray-500 mt-1">
              {props.loadingFetchUser
                ? "please wait prepare for prediction..."
                : ""}
            </span>
          </div>
        </div>

        <div className="text-center font-bold text-red-500">{msg}</div>

        {focus === "ticket_code" && (
          <div className="grid md:grid-cols-1 gap-3">
            <button
              type="submit"
              className={`border-b-4 border-blue-700 md:min-w-[160px] whitespace-nowrap px-2 py-2 rounded-lg text-white font-bold bg-blue-500  w-full text-center`}
            >
              {loading ? "Loading..." : "Submit"}
            </button>
          </div>
        )}

        {focus === "rfid_reentry" && (
          <div className="grid grid-cols-1 gap-5">
            <button
              type="submit"
              className={`border-b-4 border-blue-700 md:min-w-[160px] whitespace-nowrap px-2 py-2 rounded-lg text-white font-bold bg-blue-500  w-full text-center`}
            >
              {loading ? "Loading..." : "Submit"}
            </button>
          </div>
        )}

        {focus === "qr_code" && (
          <button
            type="submit"
            className={`min-w-[160px] px-10 py-2 rounded-lg text-white font-bold bg-purple-500  w-full text-center`}
          >
            {loading ? "Loading..." : "Submit Check QR CODE"}
          </button>
        )}

        <div className="w-full flex justify-center items-center">
          <a
            href={`./add-user?type=checkin-name`}
            className={`max-w-[160px] px-10 py-2 rounded-lg text-white font-bold border-b-4 border-green-700 bg-green-500  w-full text-center`}
          >
            Add User
          </a>
        </div>
      </form>
    </>
  );
};

export default FormVerification;
