import { useEffect, useState } from "react";
import CONFIG from "../../constanta/config";
import axios from "axios";
import Cookies from "js-cookie";
import FormVerification from "./verification.from";
import FormThankyou from "./thankyou.from";
import FormNameResult from "./name-result";
import FormRFIDResult from "./rfid-result";
import FormThankyouRFID from "./thankyou-rfid.from";
import helper from "../../utils/helper";
import FormQRResult from "./qr-result";
import { useQuery } from "../../hook/useQuery";

const PageCheckoutRFID = (props) => {
  const [options, setOptions] = useState([]);
  const [user, setUser] = useState(null);
  const [state, setState] = useState("rfid-result");
  const [qrCode, setQrCode] = useState("")
  const query = useQuery();
  const [loadingFetchUser, setLoadingFetchUser] = useState(false);

  // useEffect(() => {
  //   if (Cookies.get("token") === undefined) {
  //     window.location.href = "./home";
  //   }
  // }, []);

  useEffect(() => {
    getLitsUser();
  }, []);

  const getLitsUser = async () => {
    setLoadingFetchUser(true);

    const response = await axios.get(
      CONFIG.URL + "/user/list"
    );

    setLoadingFetchUser(false);

    if (response.status === 200) {
      setOptions(
        response.data.map((item) => {
          return {
            label: item.fullname,
            value: item.id,
          };
        })
      );
    }
  };

  const handleSuccesverification = (data, type, qr = "") => {
    setUser(data);

    if (type === "name") {
      setState("name-result");
    } else if (type === "rfid") {
      setState("rfid-result");
    } else {
      setState("qr-result");
      setQrCode(qr);
    }
  };

  const handleResetForm = () => {
    setState("rfid-result");
    // window.location.reload();
  };

  const handleSuccessCheckinName = () => {
    setState("thankyou");
  };

  const handleSuccessCheckinRFID = () => {
    setState("thankyou-rfid");
  };

  const getBG = () => {
    
    switch (query.get("id_gate")) {
      case "1":
        return props?.setting?.background;

      case "2":
        return props?.setting?.bg_pillar_1;
      case "3":
        return props?.setting?.bg_pillar_2;
      case "4":
        return props?.setting?.bg_pillar_3;
      case "5":
        return props?.setting?.bg_pillar_4;
      case "6":
        return props?.setting?.bg_pillar_5;
      case "7":
        return props?.setting?.bg_pillar_6;

      default:
       return props?.setting?.background;
    }
  }

  return (
    <>
      {
        <div
        className="w-full min-h-screen flex flex-col justify-start items-center bg-gray-200 px-5 bg-cover bg-no-repeat"
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg.jpg)`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          width: "100%",
          height: "100%",
        }}
        >
          <div className="flex flex-col justify-center items-center py-5 w-full">
            <div className="flex flex-col justify-center items-center space-y-2.5 w-full">
              {/* VERIFICATION */}
              {state === "verification" && (
                <>
                  <FormVerification
                  loadingFetchUser={loadingFetchUser}
                    onSuccess={(data, type, qrCode = "") =>
                      handleSuccesverification(data, type, qrCode)
                    }
                    options={options}
                  />
                </>
              )}

              {/* NAME RESULT */}
              {state === "name-result" && (
                <FormNameResult
                  user={user}
                  ok={() => handleResetForm()}
                  onSuccess={() => handleSuccessCheckinName()}
                />
              )}

              {/* NAME RESULT */}
              {state === "rfid-result" && (
                <FormRFIDResult
                  user={user}
                  ok={() => handleResetForm()}
                  onSuccess={() => handleSuccessCheckinRFID()}
                />
              )}

              {/* NAME RESULT */}
              {state === "qr-result" && (
                <FormQRResult
                  qrCode={qrCode}
                  user={user}
                  ok={() => handleResetForm()}
                  onSuccess={() => handleSuccessCheckinName()}
                />
              )}

              {/* THANK YOU NAME RESULT */}
              {state === "thankyou" && (
                <FormThankyou user={user} ok={() => handleResetForm()} />
              )}

              {/* THANK YOU RFID RESULT */}
              {state === "thankyou-rfid" && (
                <FormThankyouRFID ok={() => handleResetForm()} />
              )}
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default PageCheckoutRFID;
