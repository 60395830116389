import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import CONFIG from "../../constanta/config";
import axios from "axios";
import logo from "../../assets/logo-tha.png";
import InputEmail from "../../components/input/input-email";
import InputText from "../../components/input/input-text";
import InputNumber from "../../components/input/input-number";
import InputChoose from "../../components/input/input-choose";
import InputTextarea from "../../components/input/input-textarea";

const FormRegister = (props) => {
  const [loading, setLoading] = useState(false);
  const [attend, setAttend] = useState(null);
  const [guest, setGuest] = useState(null);

  const [msg, setMsg] = useState("");
  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (attend !== null) {
      setMsg("");
    }
  }, [attend]);

  useEffect(() => {
    if (guest !== null) {
      setMsg("");
    }
  }, [guest]);

  const onSubmit = (data) => {
    if (loading) return;

    setLoading(true);
    const payload = { ...data };

    try {
      axios
        .post(CONFIG.URL + "/user/added", payload, {
          headers: {
            token: props?.user?.token,
          },
        })
        .then(function (response) {
          setLoading(false);
          if (response.status === 200 && response.data?.status !== "error") {
            props.onSuccess(data);
          } else {
            setMsg("Error: " + response.data?.message);
          }
        })
        .catch(function (error) {
          setMsg("Error: Something went wrong please try again");
          setLoading(false);
        });
    } catch (error) {
      setMsg("Error: Something went wrong please try again");
      setLoading(false);
    }
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col justify-center items-center  px-5 py-5 bg-white  rounded-md w-full md:w-1/2"
      >
        {/* <img
          src={logo}
          className="w-28 object-contain mb-10 bg-white p-3 rounded-2xl "
          alt=""
        /> */}

        <div className="text-center  mb-5">
          <div className="text-2xl font-bold mb-3 uppercase">Add User</div>
        </div>

        <div className="mb-10 w-full space-y-2">
          <InputText
            label={"Details:"}
            name={"fullname"}
            register={register}
            error={errors.fullname}
          />

         
        </div>

        <div className="text-center font-bold text-red-500 mb-5">{msg}</div>

        <div className="mx-auto flex justify-center items-center space-x-4">
          <a
            href="./checkin"
            className={`w-[160px] px-10 py-2 rounded-lg text-white font-bold border-b-4 border-red-700 bg-red-500  text-center`}
          >
            Cancel
          </a >
          <button
            type="submit"
            className={`w-[160px] px-10 py-2 rounded-lg text-white font-bold border-b-4 border-green-700 bg-green-500  text-center`}
          >
            {loading ? "Loading..." : "Confirm"}
          </button>
        </div>
      </form>
    </>
  );
};

export default FormRegister;
