import { useCallback, useEffect, useRef, useState } from "react";
import QRScan from "../../components/qr_scan";
import { Controller, useForm } from "react-hook-form";
import CONFIG from "../../constanta/config";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import useUser from "../../hook/useUser";
import ReactSelect from "react-select";
import moment from "moment";
import { useQuery } from "../../hook/useQuery";
import iconCheck from "../../assets/check.png";

import Webcam from "react-webcam";
import helper from "../../utils/helper";

const FormRFIDResult = (props) => {
  const webcamRef = useRef(null);
  const [imageSrc, setImageSrc] = useState(null);
  const [loading, setLoading] = useState(false);
  const query = useQuery();
  const [checkAllow, setCheckAllow] = useState("");
  const [showGuest, setShowGuest] = useState(false);
  const [listGuest, setListGuest] = useState([]);
  const [msg, setMsg] = useState("");

  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  // Sample binary data representing a small transparent PNG image (1x1 pixel)
  const imageData = new Uint8Array([
    137,
    80,
    78,
    71, // PNG signature
    13,
    10,
    26,
    10, // \r\n\x1A\n
    0,
    0,
    0,
    13, // IHDR chunk length
    73,
    72,
    68,
    82, // IHDR
    0,
    0,
    0,
    1, // Width (1 pixel)
    0,
    0,
    0,
    1, // Height (1 pixel)
    8, // Bit depth
    2, // Color type (truecolor with alpha)
    0, // Compression method (deflate/inflate)
    0, // Filter method
    0, // Interlace method
    4,
    242,
    73,
    68,
    65,
    84,
    8,
    29,
    1,
    1,
    0,
    0,
    0,
    215,
    88,
    195,
    192,
    0,
    0,
    0,
    13,
    73,
    68,
    65,
    84,
    8,
    29,
    97,
    1,
    0,
    0,
    0,
    0,
    10,
    245,
    97,
    24,
    1,
    0,
    0,
    0,
    0,
    0,
    0,
    141,
    56,
    66,
    172,
    0,
    0,
    0,
    0,
    73,
    69,
    78,
    68,
    174,
    66,
    96,
    130,
  ]);

  // Create a Blob object from the image data
  const blobData = new Blob([imageData], { type: "image/png" });

  // Create a new File object
  const newImageFile = new File([blobData], "photo.png", {
    type: "image/png",
    lastModified: Date.now(),
  });

  // useEffect(() => {
  //   if (checkAllow !== "") {
  //     if (
  //       checkAllow === "allowed-to-checkout" &&
  //       query.get("id_gate") !== "1"
  //     ) {
  //       setTimeout(() => {
  //         setLoading(true);

  //         handleCheckout();
  //       }, 10);
  //       return;
  //     }
  //   }
  // }, [checkAllow]);

  // useEffect(() => {
  //   initAllowed();
  // }, [props.user]);

  useEffect(() => {
    if (checkAllow !== "") {
      if (
        checkAllow === "already-checkout" ||
        checkAllow === "havent-checkin"
      ) {
        setTimeout(() => {
          props.ok();
        }, 1500);
        return;
      }
    }
  }, [checkAllow]);

  const togleGuest = (idx) => {
    let arr = [...listGuest];
    arr[idx].active = !arr[idx].active;
    setListGuest(arr);
  };

  const onSubmit = (data) => {
    setMsg("");

    if (imageSrc === null) {
      setMsg("Please capture photo");
      return;
    }

    if (loading) return;

    setLoading(true);

    handleCheckout(data);
  };

  const videoConstraints = {
    width: { min: 720 },
    height: { min: 480 },
    facingMode: "user",
  };

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  const capture = useCallback(() => {
    setImageSrc(webcamRef.current.getScreenshot());
  }, [webcamRef]);

  const retake = () => {
    setImageSrc(null);
  };

  const canSubmit = () => {
    if (imageSrc === null) {
      return false;
    } else {
      return true;
    }
  };

  const handleRFIDValidation = async (rfid) => {
    const payload = { rfid: rfid };

    var form_data = new FormData();
    for (var key in payload) {
      form_data.append(key, payload[key]);
    }

    try {
      const response = await axios.post(
        CONFIG.URL + "/user/valid-rfid",
        form_data
      );
      if (response?.data?.status === "sukses") {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };

  const handleCheckout = (data) => {
    if (imageSrc === null) {
      return;
    }

    var file = dataURLtoFile(imageSrc, `${Date.now()}.png`);

    const payload = {
      rfid: data.rfid,
      file: file,
    };

    var form_data = new FormData();

    for (var key in payload) {
      form_data.append(key, payload[key]);
    }

    try {
      axios
        .post(CONFIG.URL + "/user/checkout-rfid", form_data)

        .then(function (response) {
          setLoading(false);
          if (response.status === 200 && response.data?.status !== "error") {
            props.onSuccess();
          } else {
          }
        })
        .catch(function (error) {
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
    }
  };

  const initAllowed = () => {
    if (props.user !== null) {
      if (props.user.checkout_time === "0000-00-00 00:00:00") {
        if (props.user.checkin_time !== "0000-00-00 00:00:00") {
          setCheckAllow("allowed-to-checkout");
        } else {
          setCheckAllow("havent-checkin");
        }
      } else {
        if (
          moment(props.user.checkin_time) < moment(props.user.checkout_time) &&
          props.user.reentery_time === "0000-00-00 00:00:00"
        ) {
          setCheckAllow("already-checkout");
        } else if (
          moment(props.user.reentery_time) < moment(props.user.checkout_time)
        ) {
          setCheckAllow("already-checkout");
        } else {
          setCheckAllow("allowed-to-checkout");
        }
      }
    } else {
      setCheckAllow("wrong-gate");
    }
  };

  const resetForm = () => {
    setValue("rfid", "");
    reset();
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="space-y-2 px-5 py-2 bg-white rounded-md items-center justify-center flex flex-col w-full md:w-1/2 transition-all"
      >
        <div className="text-center font-bold">
          <div className="mb-2 text-xl">CHECKOUT</div>
          <hr className="mb-1" />
        </div>

        <div
          className={` cursor-pointer text-white flex flex-col justify-center items-center  text-black font-bold px-5 py-1 rounded`}
        >
          <>
            {/* SCAN QR CODE / RFID */}
            <div className="flex flex-col w-full">
              <div className="flex justify-between mb-1">
                <label className="text-black w-full font-bold">
                  Scan RFID RE-ENTRY
                </label>
                <button
                  type="button"
                  onClick={() => resetForm()}
                  className="border-b-2 border-red-700  bg-red-500 px-2 py-1 text-white text-xs rounded font-bold"
                >
                  Clear
                </button>
              </div>

              <input
                maxLength={10}
                autoFocus
                autoComplete="off"
                role="presentation"
                className="border-2 px-2 h-[38px] rounded text-black"
                {...register("rfid", {
                  required: "RFID Empty",
                  minLength: {
                    value: 10,
                    message: "RFID 10 Character",
                  },
                  maxLength: {
                    value: 10,
                    message: "RFID 10 Character",
                  },
                  validate: handleRFIDValidation,
                })}
              />
            </div>

            <div className="flex justify-start items-start w-full">
              {errors["rfid"]?.type === "validate" ? (
                <span className="text-red-500 text-sm ">
                  RFID is already in use.
                </span>
              ) : (
                <span className="text-red-500 text-sm">
                  {errors[`rfid`]?.message}
                </span>
              )}
            </div>

            <div className="relative shadow-lg drop-shadow-lg overflow-hidden max-w-[320px] mt-5">
              <div className={`${imageSrc !== null ? "invisible" : "visible"}`}>
                <Webcam
                  audio={false}
                  height={600}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                  width={800}
                  videoConstraints={videoConstraints}
                  screenshotQuality={1}
                  imageSmoothing={true}
                  mirrored
                />
              </div>

              {imageSrc !== null && (
                <img
                  alt=""
                  src={imageSrc}
                  className={`${
                    imageSrc !== null ? "block" : "hidden"
                  } w-full h-full object-contain absolute top-0 left-0`}
                />
              )}
            </div>

            <div className="flex justify-center items-center my-2">
              {imageSrc === null ? (
                <button
                  onClick={() => capture()}
                  type="button"
                  className={`cursor-pointer w-max px-4 py-1 rounded-lg text-white font-bold bg-blue-500  text-center`}
                >
                  Capture
                </button>
              ) : (
                <button
                  onClick={() => retake()}
                  type="button"
                  className={`cursor-pointer w-max px-4 py-1 rounded-lg text-white font-bold bg-orange-500  text-center`}
                >
                  Retake
                </button>
              )}
            </div>
          </>
        </div>

        <div className="text-center font-bold text-red-500">{msg}</div>

        <div>
          <div className="mx-auto flex justify-center items-center space-x-4 my-1">
            <button
              type="button"
              onClick={() => props.ok()}
              className={`px-10 py-2 rounded-lg text-white font-bold bg-red-500  w-full text-center`}
            >
              CANCEL
            </button>

            <button
              type="submit"
              className={` min-w-[160px] px-10 py-2 rounded-lg text-white font-bold bg-green-500  w-full text-center`}
            >
              {loading ? "LOADING..." : "SUBMIT"}
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default FormRFIDResult;
